import storage_service from "./storage_service";
import { abUserActiveExperiments } from '../../utils/abExperiments'
import { server } from '../../utils/server'

const { cookies, storage } = storage_service;
const CORS_LOG_EVENT_RESOURCE = process.env.CORS_LOG_EVENT_RESOURCE || ''

let tracking = {

  isProduction () {
    return !/sandbox|local|dev|demo|staging|ops/.test(window.location.hostname);
  },

  getEnv() {
    if (/sandbox|demo|staging|ops/.test(window.location.hostname)) {
      return "staging";
    } else if (/local|dev/.test(window.location.hostname)) {
      return "development";
    } else {
      return "production";
    }
  },

  trackConversion (isGoodLead, orderId) {

    var ns = document.createElement('noscript');
    var iframe = document.createElement('iframe');
    var production = this.isProduction();
    var replyImg;
    let { visitor_id,
          original_utm_campaign,
          original_utm_source,
          original_utm_medium,
          original_utm_term,
          original_utm_content
        } = cookies.parse();
    // client tokens are no longer in use; use the hapi token instead
    const hapi_user_token = cookies.get(`hapi_user_${this.getEnv()}`)
    orderId = this.gtmTruncate(orderId);

    // Google
    // New GTM custom event trigger
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'lead_conversion',
      lead_id: window.HomeLight && HomeLight.Lead.id,
      order_id: orderId,
      client_token: hapi_user_token,
      visitor_id: visitor_id,
      utm_campaign: original_utm_campaign,
      utm_source: original_utm_source,
      utm_medium: original_utm_medium,
      utm_term: original_utm_term,
      utm_content: original_utm_content
    });

    // facebook
    try {
      this.facebookEvent('LeadSubmitted');
      // window._fbq.push(['track', '6018851989695', {'value':'0.00','currency':'USD'}]);
    } catch(err) {}

    // Microsoft Bing
    if (window.mstag) {
      ns = document.createElement('noscript');
      iframe = document.createElement('iframe');
      iframe.src = '//flex.msn.com/mstag/tag/293f0f54-2679-410d-9f5c-c739e0c70192/analytics.html?dedup=1&domainId=2907948&type=1&actionid=230774';
      iframe.frameborder = '0';
      iframe.scrolling = 'no';
      iframe.width = '1';
      iframe.height = '1';
      iframe.style.visibility = 'hidden';
      iframe.style.display = 'none';
      ns.appendChild(iframe);
      document.body.appendChild(ns);
    }
    // Microsoft Bing Universal Event Tracking
    try {
      window.uetq = window.uetq || [];
      window.uetq.push(
        'event',
        'submitSuccess',
        { 'event_category': 'Lead' }
      );
    } catch (err) { }

    // reply
    replyImg = new Image(1, 1);
    replyImg.src = '//www.buyerlink.com/clicks/conversion/?c=1&u=3074';

    if (production) {
      console.log(['tracking conversion. lead is ',(isGoodLead ? 'good' : 'bad')].join(''));
    }

    //pinterest
    var pinterestImage = new Image(1, 1);
    pinterestImage.src = '//ct.pinterest.com/?tid=Ayjst7E0fJG&value=0.00&quantity=1';

    let leadLocation;

    try {
      leadLocation = (HomeLight.Lead.id && HomeLight.Lead.id + '@' + window.location.href);
    } catch (err) {
      leadLocation = window.location.href;
    }

    if (!leadLocation) {
      leadLocation = window.location.href;
    }

    if (storage.get('trac_conv')) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'track_event',
        'category': 'duplication',
        'action': 'trackConversion',
        'label': 'duplicateConversion',
        'value': leadLocation
      });

      console.log('Track Event > ', 'duplication', 'trackConversion', 'duplicateConversion', leadLocation);
    }

    storage.set('trac_conv', 't');
  },

  trackEvent (category, action, label, value, allowStringValue = false, payload = {}) {
    var _this = this;
    var result;
    var map = {
      labels : {
        'buy' : 'Buy',
        'sell' : 'Sell',
        'renter' : 'Rent',
        'single_family_home' : 'SFM',
        'condominium' : 'Condo',
        'townhomes' : 'Townhome'
      },
      user_type : function (label) {
        _this.facebookEvent(map.labels[label]);
      },
      price : function (label) {
        var tag;
        label = label.substring(1).toNumber();
        tag = (label <= 300000) ? '300korless' :
          (label < 700000 && label > 300000) ? '300k-700k' :
            (label >= 700000) ? '700kormore' : label;
        _this.facebookEvent(tag);
      },
      property_type : function (label) {
        _this.facebookEvent(map.labels[label] || 'OtherHomeTypes');
      },
      view_agent_profile : function () {
        _this.facebookEvent('AgentProfileEntry');
      }
    };

    if (isNaN(value) && !allowStringValue) {
      value = 0;
    }

    //just log to console if no track
    if (cookies.get('no_track')){
      console.log(['track event', category, action, label, value].join(' '));
    }  else {
      result = label && map[action] && map[action](label);

      const {
        visitor_id
      } = cookies.parse();
      // client tokens are no longer in use; use the hapi token instead
      const hapi_user_token = cookies.get(`hapi_user_${this.getEnv()}`)

      // GTM
      if (!this.isProduction()) {
        console.log(['track event', 'hapi_user_token= ' + (hapi_user_token ? hapi_user_token : 'Not Set'), 'visitor_id= ' + visitor_id, category, action, label, value].join(' '));
      }

      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'track_event',
        category: category,
        action: action,
        label: label,
        value: value,
        client_token: hapi_user_token,
        visitor_id: visitor_id,
        experiment_name: Object.keys(abUserActiveExperiments()).join(', '),
        experiment_value: Object.values(abUserActiveExperiments()).join(', ')
      });

      const eventName =`${category} ${action} ${label}`
      this._sendFacebookServerSideEvent(eventName, {
        category,
        action,
        label
      })

      // Microsoft Bing
      try {
        window.uetq = window.uetq || [];
        window.uetq.push({
          'ec':category,
          'ea':action
        });
      } catch(err) {}

      var tokenElem = document.querySelector('[name=csrf-token]');

      if (typeof payload === 'object') {
        payload = JSON.stringify(payload);
      }

      var data = {
        category: category,
        sub_category: action,
        label: label,
        value: value,
        payload: payload
      }

      var dataStr = [];
      for (var key in data) {
        var encodedKey = encodeURIComponent(key);
        var encodedVal = encodeURIComponent(data[key]);
        dataStr.push(`${encodedKey}=${encodedVal}`);
      }

      dataStr = dataStr.join('&');

      // log in HomeLight session_events table
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${CORS_LOG_EVENT_RESOURCE}/log_event`, true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      if (CORS_LOG_EVENT_RESOURCE !== '') {
        xhr.withCredentials = true;
      }

      xhr.send(dataStr);
    }
  },

  trackSemEvent(name, eventData, platform) {
    try {
      let tokenElem = document.querySelector('[name=csrf-token]');
      let data = {
        event_name: name,
        event_data: eventData,
        platform: platform,
        authenticity_token: (tokenElem ? tokenElem.getAttribute('content') : undefined)
      }

      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/log_sem_event', true);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.send(JSON.stringify(data));
    } catch (err) {
      window.Sentry && window.Sentry.captureException(err)
    }
  },

  customGtmConversion(eventName, orderId) {
    // Testing a hypothesis that Google Ads check orderId against all other
    // conversion events for uniqueness. Disabling this for now
    // orderId = this.gtmTruncate(orderId);

    dataLayer.push({
      'event': eventName,
      // 'order_id': orderId
    });
  },

  // GTM Order ID has a char limit of 64
  gtmTruncate(string) {
    if (string && string.length > 64) {
      string = string.substring(string.length - 64, string.length);
    }

    return string;
  },

  // this should be private, don't use this outside of this class.
  facebookEvent (event) {
    if ( this.isProduction() ) {
      window.fbq('trackCustom', event);
    } else {
      console.log('facebook event: ' + event);
    }

    this._sendFacebookServerSideEvent(event)
  },

  _sendFacebookServerSideEvent(eventName, customData = {}) {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/facebook_events', true);

      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').getAttribute('content'));

      const data = JSON.stringify({
        event: {
          name: eventName,
          custom_data: customData
        },
        fbc: cookies.get('_fbc'),
        fbp: cookies.get('_fbp'),
        lead_email: window.HOMELIGHT_GOOGLE_CONVERSIONS_TRACKING_USER_EMAIL,
        source_url: window.location.href
      });

      xhr.send(data);
    } catch (err) {
      window.Sentry && window.Sentry.captureException(err)
    }
  },

  // track a page view.
  // This should only be needed for ajax calls that we want to treat as page loads
  trackPageView (path) {
    // this method is being called Detached from it's parent scope in some places.
    // this means that if you are to update this, don't expect the value of `this` to be what you
    // think it is. In some cases, it will not be correct, and other cases it will be incorrect.
    if (!this.isProduction()) {
      console.log('GA track ' + path);
    }

    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      "event": "track_page_view",
      "page_view_path": path,
    });
  },

  trackTaboolaRetargetting (step) {
    try {
      window._tfa = window._tfa || [];

      if (step == 'buy_sell') {
        window._tfa.push({ notify: 'mark', type: 'BuySellRent' });
      } else if (step == 'price_bucket') {
        window._tfa.push({ notify: 'mark', type: 'PriceBucket' });
      } else if (step == 'property_type') {
        window._tfa.push({ notify: 'mark', type: 'TypeOfHome' });
      } else if (step == 'timeline') {
        window._tfa.push({ notify: 'mark', type: 'HowSoon' });
      } else if (step == 'additional_services') {
        window._tfa.push({ notify: 'mark', type: 'AlsoLookingToBuySell' });
      } else if (step == 'name_email') {
        window._tfa.push({ notify: 'mark', type: 'ContactInfo' });
      }
    } catch(err) {}
  },

  // for 'hybrid' remarketing...
  // load a conversion pixel to tell google to
  // remarket to a client based on the
  // "most_important" response they give
  trackRemarketing (user_type, price) {

    // The commented code here, was written, but never used.
    // There is a fallback to the pixel that was fired when no arguments were passed.
    // This is still called without arguments somewhere. be aware.

    // var mapping = {
    //   'seller' : {
    //     'experience'    : ['seller_exp_a', 'seller_exp_b'],
    //     'top_dollar'    : ['seller_price_a', 'seller_price_b'],
    //     'availability'  : ['seller_avail_a', 'seller_avail_b'],
    //     'sells_fast'    : ['seller_fast_a', 'seller_fast_b']
    //   },
    //   'buyer' : {
    //     'experience'      : ['buyer_exp_a'],
    //     'finds_bargains'  : ['buyer_price_a'],
    //     'availability'    : ['buyer_avail_a']
    //   }
    // };

    //default to seller since those leads are more valuable
    // if (!user_type) {
    //   user_type = 'seller';
    // }

    // //default to experience since it's shared by both
    // if (!agent_type) {
    //   agent_type = 'experience';
    // }

    // Item ID is the field name in the google remarketing spreadsheet, so use it here
    // var itemId = mapping[user_type][agent_type][
    //   Math.floor(Math.random() * mapping[user_type][agent_type].length
    //   )
    // ];

    if (this.isProduction()) {
      let body = document.getElementsByTagName('body')[0];
      let image = new Image(1,1);
      if (user_type == 'sell' && (!price)) {
        // var google_conversion_id = 1037604267;
        // var google_conversion_label = "_6z9CLyE22kQq6vi7gM";
        // var google_custom_params = window.google_tag_params;
        // var google_remarketing_only = true;
        image.src = '//googleads.g.doubleclick.net/pagead/viewthroughconversion/1037604267/?value=1.00&currency_code=USD&label=_6z9CLyE22kQq6vi7gM&guid=ON&script=0'
      } else if (user_type == 'buy' && (!price)) {
        image.src = '//googleads.g.doubleclick.net/pagead/viewthroughconversion/1037604267/?value=1.00&currency_code=USD&label=h8qPCMfJxWkQq6vi7gM&guid=ON&script=0'
      } else if (price && price.toNumber() > 500000) {
        image.src = '//googleads.g.doubleclick.net/pagead/viewthroughconversion/1037604267/?value=1.00&currency_code=USD&label=B-PCCPjewWkQq6vi7gM&guid=ON&script=0'
        var image2 = new Image(1,1);
        if (user_type == 'buy') {
          image2.src = "//googleads.g.doubleclick.net/pagead/viewthroughconversion/1037604267/?value=1.00&currency_code=USD&label=1-RaCMrRm2oQq6vi7gM&guid=ON&script=0"
        } else if(user_type == 'sell') {
          image2.src = "//googleads.g.doubleclick.net/pagead/viewthroughconversion/1037604267/?value=1.00&currency_code=USD&label=l7mrCMfRm2oQq6vi7gM&guid=ON&script=0"
        }
      } else {
        image.src = '//googleads.g.doubleclick.net/pagead/viewthroughconversion/1037604267/?value=0&guid=ON&script=0';
      }
      body.appendChild(image);
      body.appendChild(image2);
    } else {
      console.log('GA remarketing track ut: ' + user_type + ' price: '+ price);
    }
  },


  // index: The slot for the custom variable. Required. This is a number whose value can range from 1 - 5, inclusive. A custom variable should be placed in one slot only and not be re-used across different slots.
  // name: The name for the custom variable. Required. This is a string that identifies the custom variable and appears in the top-level Custom Variables report of the Analytics reports.
  // value: The value for the custom variable. Required. This is a string that is paired with a name. You can pair a number of values with a custom variable name. The value appears in the table list of the UI for a selected variable name. Typically, you will have two or more values for a given name. For example, you might define a custom variable name gender and supply male and female as two possible values.
  // opt_scope: The scope for the custom variable. Optional. As described above, the scope defines the level of user engagement with your site. It is a number whose possible values are 1 (visitor-level), 2 (session-level), or 3 (page-level). When left undefined, the custom variable scope defaults to visitor-level interaction.
  trackCustom(index, name, value, opt_scope) {
    opt_scope = opt_scope || 1;
    index = index || 1;
    this.trackEvent("_setCustomVar", index, name, value)

    if (!this.isProduction()) {
      console.log(['set custom variable', index, name, value, opt_scope].join(' '));
    }
  }
};

Object.keys(tracking).forEach(key => {
  if (tracking.hasOwnProperty(key) && typeof tracking[key] === 'function' ) {
    tracking[key] = tracking[key].bind(tracking);
  }
});

export default tracking
