import React from 'react';
import classNames from 'classnames';

import {useDropdown} from '../../hooks/useDropdown';
import CaretDown from '../icons/CaretDown';
import CheckIcon from '../icons/CheckIcon';
import '../../styles/global/_dropdown.scss';

const Dropdown = ({
  options,
  selectedLabel,
  setSelected,
  onSelect,
  rounded,
  styles,
  selectedValue,
  hideCheckmark,
  triggerBorderActive,
  id = 'dropdown',
}) => {
  const numberOfItems = options.length;
  const {triggerProps, itemProps, isOpen} = useDropdown(numberOfItems);

  const select = (e, option) => {
    e.preventDefault()
    setSelected(option)

    if (onSelect) {
      onSelect()
    }
  }

  const optionsContainerStyles = isOpen
    ? {display: 'block', ...styles?.optionsContainerStyles}
    : {display: 'none'};

  return (
    <div className={`dropdown-container${isOpen ? ' menu-open' : ''}`} id={id}>
      <div
        className={classNames('dropdown-trigger', {
          'dropdown-trigger--rounded': !!rounded,
          'dropdown-trigger--active': !!triggerBorderActive,
        })}
        {...triggerProps}
        {...styles?.triggerStyles}
      >
        <span>{selectedLabel}</span>
        <CaretDown />
      </div>
      <ul className='dropdown-options-container' style={optionsContainerStyles}>
        {options.map((option, index) => (
          <li key={`${option.label}`}>
            {(selectedLabel === option.label || selectedValue === option.value) ? (
              <div className='dropdown-option selected-option'
                onClick={(e) => select(e, option)}
                {...itemProps[index]}
              >
                {!hideCheckmark && (
                  <span className="check-icon-wrapper">
                    <CheckIcon />
                  </span>
                )}
                {option.label}
              </div>
            ) : (
              <div className='dropdown-option'
                onClick={(e) => select(e, option)}
                {...itemProps[index]}
              >
                {option.label}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Dropdown;
