import React from 'react';

const CheckCircledGreenIcon = () => (
  <svg
    width="118"
    height="118"
    viewBox="0 0 118 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="118" height="118" rx="59" fill="#CEF0E4" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M87.5484 58.2381C87.5484 74.005 74.7669 86.7865 59 86.7865C43.2332 86.7865 30.4517 74.005 30.4517 58.2381C30.4517 42.4712 43.2332 29.6897 59 29.6897C74.7669 29.6897 87.5484 42.4712 87.5484 58.2381Z"
      fill="#38BF8F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.707 73.3543L76.888 52.1732C77.6073 51.454 77.6073 50.2878 76.888 49.5685L74.2833 46.9638C73.5641 46.2445 72.3979 46.2445 71.6785 46.9638L54.4046 64.2377L46.3398 56.1729C45.6205 55.4536 44.4543 55.4536 43.7349 56.1729L41.1302 58.7776C40.411 59.4968 40.411 60.663 41.1302 61.3823L53.1021 73.3542C53.8215 74.0735 54.9876 74.0735 55.707 73.3543Z"
      fill="white"
    />
  </svg>
);

export default CheckCircledGreenIcon;
